import React, { createContext, useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import { store } from '../../App';

function GallaryTwo() {

  const [photoIndex, setPhotoIndex] = useState(0);
  // const [isOpen, setIsOpen] = useState(false);


  const [menus, setMenus, isOpen, setIsOpen, navbarclose, setNavbarClose] = useContext(store)

  const products = [
    { image: "Images/gallery/1.jpg", title: "", link: "capsule-elevators", id: 1 },
    { image: "Images/gallery/2.jpg", title: "", link: "passenger-Elevators", id: 2 },
    { image: "Images/gallery/3.jpg", title: " ", id: 3 },
    { image: "Images/gallery/4.jpg", title: "", id: 4 },
    { image: "Images/gallery/5.jpg", title: "", link: "capsule-elevators", id: 5 },
    { image: "Images/gallery/6.jpg", title: "", link: "passenger-Elevators", id: 6 },
    { image: "Images/gallery/7.jpg", title: ".", link: "passenger-elevators-autodoor", id: 7 },
    { image: "Images/gallery/8.jpg", title: " ", id: 8 },
    { image: "Images/gallery/9.jpg", title: "", link: "capsule-elevators", id: 9 },
    { image: "Images/gallery/10.jpg", title: "", link: "passenger-Elevators", id: 10 },
    { image: "Images/gallery/11.jpg", title: "", link: "passenger-elevators-autodoor", id: 11 },
    { image: "Images/gallery/12.jpg", title: "", id: 12 },
    { image: "Images/gallery/13.jpg", title: "", link: "capsule-elevators", id: 13 },
    { image: "Images/gallery/14.jpg", title: "", link: "passenger-Elevators", id: 14 },
    { image: "Images/gallery/15.jpg", title: "", link: "passenger-elevators-autodoor", id: 15 },
    { image: "Images/gallery/16.jpg", title: "", id: 16 },

  ]


  return (
    <>
      <div>
        <div className="gallery">

          <div className="gallery__strip__wrapper">
            <div className="gallery__strip one">
              <div className="photo">
                {products.slice(0, 4).map((item) => {
                  return (
                    <>
                      <div className="photo__image" as={Link} to={item.link}>
                        <img src={item.image} onClick={() => { setIsOpen(true); setPhotoIndex(item.id); setNavbarClose("navbar-close") }} />
                      </div>
                      <div className="photo__name">
                        {item.title}
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>

          <div className="gallery__strip__wrapper">
            <div className="gallery__strip four">
              <div className="photo">
                {products.slice(4, 8).map((item) => {
                  return (
                    <>
                      <div className="photo__image"><img src={item.image} onClick={() => { setIsOpen(true); setPhotoIndex(item.id); setNavbarClose("navbar-close") }} /></div>
                      <div className="photo__name">{item.title}</div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>

          <div className="gallery__strip__wrapper">
            <div className="gallery__strip two">
              <div className="photo">
                {products.slice(8, 12).map((item) => {
                  return (
                    <>
                      <div className="photo__image"><img src={item.image} onClick={() => { setIsOpen(true); setPhotoIndex(item.id); setNavbarClose("navbar-close") }} /></div>
                      <div className="photo__name">{item.title}</div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>

          <div className="gallery__strip__wrapper">
            <div className="gallery__strip three">
              <div className="photo">
                {products.slice(12, 16).map((item) => {
                  return (
                    <>
                      <div className="photo__image"><img src={item.image} onClick={() => { setIsOpen(true); setPhotoIndex(item.id); setNavbarClose("navbar-close") }} /></div>
                      <div className="photo__name">{item.title}</div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>

        </div>
      </div>

      <div>
        {isOpen && (
          <Lightbox
            mainSrc={products[photoIndex].image}
            nextSrc={products[(photoIndex + 1) % products.length].image}
            prevSrc={products[(photoIndex + products.length - 1) % products.length].image}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + products.length - 1) % products.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % products.length)
            }
          />
        )}

      </div>

    </>
  )
}

export default GallaryTwo



















