import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from './Homepages/Footer';

const ServiceSection = () => {
  const services = [
    {
      title: "Installation Services",
      details: [
        "Professional Installation: Our skilled technicians ensure that your Hyundai elevators are installed to the highest standards, guaranteeing safety and reliability.",
        "Customized Solutions: We work closely with you to understand your specific requirements and provide tailored installation solutions that meet your needs.",
        "Timely Execution: We prioritize efficient project management to ensure that your elevators are installed on time and within budget."
      ],
      icon: "https://cdn-icons-png.freepik.com/256/3073/3073775.png"
    },
    {
      title: "Maintenance Services",
      details: [
        "Regular Maintenance: Our comprehensive maintenance plans include regular inspections and servicing to keep your elevators running smoothly and efficiently.",
        "Preventive Maintenance: Our approach prioritizes preventive maintenance, proactively detecting and resolving potential issues to reduce downtime and maximize the lifespan of your elevators.",
        "Emergency Repairs: Our team is available 24/7 to provide prompt and reliable emergency repair services, ensuring that your elevators are always operational."
      ],
      icon: "https://cdn-icons-png.freepik.com/256/2189/2189976.png"
    },
    {
      title: "Modernization Services",
      details: [
        "Upgrading Existing Elevators: We provide modernization solutions to enhance your elevators with advanced technology, boosting performance, safety, and energy efficiency.",
        "Aesthetic Enhancements: Transform the appearance of your elevators with our aesthetic upgrade options, featuring redesigned cabin interiors, modern lighting, and updated control panels.",
        "Compliance Upgrades: Ensures that your elevators meet the latest safety and accessibility standards with our compliance upgrade services."
      ],
      icon: "https://cdn-icons-png.freepik.com/256/5087/5087199.png"
    },
    {
      title: "Consultation Services",
      details: [
        "Expert Advice: Our team of experts is available to provide professional advice and guidance on all aspects of elevator installation, maintenance, and modernization.",
        "Project Planning: We assist with project planning and management, ensuring that your elevator projects are executed smoothly and efficiently.",
        "Customized Solutions: We work with you to develop customized elevator solutions that meet your specific needs and requirements."
      ],
      icon: "https://cdn-icons-png.freepik.com/256/1571/1571327.png"
    },
    {
      title: "Customer Support",
      details: [
        "Dedicated Support Team: Our customer support team is always ready to assist you with any questions or concerns you may have.",
        "Training and Education: We provide training and education for building managers and maintenance staff to ensure the proper operation and care of your elevators.",
        "Comprehensive Documentation: We offer detailed documentation and manuals to help you understand and manage your elevator systems effectively."
      ],
      icon: "/support.png"
    }
  ];

  const splitText = (text) => {
    const [boldPart, regularPart] = text.split(':');
    return (
      <>
        <strong>{boldPart}:</strong>
        {regularPart && ` ${regularPart}`}
      </>
    );
  };

  return (
    <>
      <section className="service_section layout_padding mt-5 mb-5">
        <div className="service_container">
          <Container>
            {/* Section Header */}
            <div className="heading_container text-center">
              <div className="section-title">
                <h2>Our Services</h2>
              </div>
              <p className="mb-4">
                Comprehensive Elevator Solutions Tailored to Your Needs. At Techno Elevators LLC, we offer a full range of services to ensure the optimal performance and longevity of your Hyundai elevators. Our team of experienced professionals provides top-notch service, from installation to maintenance and beyond.
              </p>
            </div>

            {/* Service Cards */}
            <Row className="g-4">
              {services.map((service, index) => (
                <Col md={6} key={index}>
                  <div className="box shadow-sm p-4 h-100">

                    <div className="detail-box">
                      <h5 className="text-center mb-3">{service.title}</h5>
                      <div className=" text-center mb-3">
                        <img src={service.icon} alt={service.title} style={{ height: '64px' }} />
                      </div>
                      <ul>
                        {service.details.map((detail, i) => (
                          <li key={i} className="mb-2">{splitText(detail)}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ServiceSection;
