import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FaEnvelope, FaFacebook, FaInstagram, FaLinkedin, FaMapLocation, FaMapPin, FaPhone, FaTwitter } from 'react-icons/fa6';
import Footer from './Homepages/Footer';


const InfoSection = () => {

  const images = [
    { image: "/Images/images/f1.png" },
    { image: "/Images/images/f2.png" },
    { image: "/Images/images/f3.png" },
    { image: "/Images/images/f4.png" },
    { image: "/Images/images/f5.png" },
    { image: "/Images/images/f6.png" },






  ]
  return (

    <>
      <Container fluid style={{ padding: "0px" }}>

        <section className="contact_section long_section">
          <Container>

            <Row>
              <div className='text-center mb-5 mt-5 section-title'>


                <p >Contact Us</p>
              </div>

              <Col md={6} style={{ paddingLeft: '7px' }}>
                <div className="form_container">

                  <Form>
                    <Form.Group>
                      <Form.Control type="text" placeholder="Your Name" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control type="text" placeholder="Mobile  Number" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control type="email" placeholder="Email" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        type="text" placeholder="Message" className="message-box" />
                    </Form.Group>
                    <div className="btn_box text-center">
                      <Button type="submit">SEND</Button>
                    </div>
                  </Form>
                </div>
              </Col>
              <Col md={6} style={{ paddingRight: '0px' }}>
                <div className="map_container">
                  <div className="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d15968.111025861048!2d58.39836609506744!3d23.585038294896684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2sin!4v1732708288873!5m2!1sen!2sin" width="100%" height="500px" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    {/* Replace the following div with your map component or integration */}
                    <div id="googleMap"></div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <section className="info_section long_section">
      <Container>
        <div className="contact_nav">
          <a href="#">
            <FaPhone size={'25px'}/>
            <span>Call : +01 123455678990</span>
          </a>
          <a href="#">
            <FaEnvelope size={'25px'}/>
            <span>Email : demo@gmail.com</span>
          </a>
          <a href="#">
            <FaMapPin size={'25px'}/>
            <span>Location</span>
          </a>
        </div>

        <div className="info_top">
          <Row>
            <Col sm={6} md={4} lg={3}>
              <div className="info_links">
                <h4>QUICK LINKS</h4>
                <div className="info_links_menu">
                  <a className="" href="index.html">
                    Home <span className="sr-only">(current)</span>
                  </a>
                  <a className="" href="about.html">
                    About
                  </a>
                  <a className="" href="furniture.html">
                    Furniture
                  </a>
                  <a className="" href="blog.html">
                    Blog
                  </a>
                  <a className="" href="contact.html">
                    Contact Us
                  </a>
                </div>
              </div>
            </Col>
            <Col sm={6} md={4} lg={3} className="mx-auto">
              <div className="info_post">
                <h5>INSTAGRAM FEEDS</h5>
                <div className="post_box">
                  { images.map((item,index) => (
                    <div className="img-box" key={index}>
                      <img src={item.image} alt={`Feed ${index}`} />
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="info_form">
                <h4>SIGN UP TO OUR NEWSLETTER</h4>
                <Form>
                  <Form.Control type="text" placeholder="Enter Your Email" />
                  <Button type="submit">Subscribe</Button>
                </Form>
                <div className="social_box">
                  <a href="#">
                    <FaFacebook/>
                  </a>
                  <a href="#">
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                    <FaTwitter/>
                  </a>
                  <a href="#">
                    <FaLinkedin/>
                  </a>
                  <a href="#">
                    <FaInstagram/>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section> */}
      </Container>
      <Footer />
    </>
  );
};

export default InfoSection;
