import React, { useEffect, useRef, useState } from 'react'
import { Card, Container, Row, Col } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel';
import { Link, useLocation } from 'react-router-dom';
import Footer from './Homepages/Footer';
import AOS from 'aos';
import { Accordion } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';



const keypoints = [
  {
    "heading": "Smooth Ride Quality",
    "text": "Advanced control systems and precision engineering ensure a smooth and comfortable ride."
  },
  {
    "heading": "Spacious Cabins",
    "text": "Our elevators are designed with spacious cabins to accommodate more passengers comfortably."
  },
  {
    "heading": "User-Friendly Interface",
    "text": "Easy-to-use control panels and intuitive interfaces make our elevators accessible to all users."
  },
  {
    "heading": "Quiet Operation",
    "text": "Noise reduction technology ensures a quiet and peaceful ride, enhancing the comfort of passengers."
  }
]

const elevatorBenefits = [
  {
    heading: "Advanced Technology",
    text: "Hyundai passenger elevators are equipped with cutting-edge technology that ensures smooth and quiet operation, enhancing the overall user experience."
  },
  {
    heading: "Safety First",
    text: "Our elevators are designed with multiple safety features, including emergency braking systems, overload sensors, and automatic rescue devices, to ensure the safety of all passengers."
  },
  {
    heading: "Energy Efficiency",
    text: "Hyundai elevators are built with energy-efficient components that reduce power consumption and lower operational costs, contributing to a greener environment."
  },
  {
    heading: "Customizable Designs",
    text: "We offer a variety of design options to match the aesthetic of your building. From sleek and modern to classic and elegant, our elevators can be customized to fit your style."
  },
  {
    heading: "Durability and Reliability",
    text: "Built with high-quality materials and robust engineering, Hyundai passenger elevators are known for their durability and long-lasting performance."
  }
];


const elevatorApplications = [
  {
    heading: "Residential Buildings",
    text: "Enhance the convenience and accessibility of your home with our stylish and efficient passenger elevators."
  },
  {
    heading: "Commercial Buildings",
    text: "Improve the flow of people in office buildings, shopping malls, and hotels with our reliable and high-capacity elevators."
  },
  {
    heading: "Public Spaces",
    text: "Ensure smooth and efficient transportation in public buildings such as hospitals, airports, and train stations."
  }
];

const homeElevatorFeatures = [
  {
    heading: "Stylish Design",
    text: "Our home elevators are available in a variety of styles and finishes to complement your home’s interior. Whether you prefer a modern look or a classic design, we have options to suit your taste."
  },
  {
    heading: "Compact and Space-Efficient",
    text: "Designed to fit seamlessly into your home, our elevators require minimal space and can be installed in new or existing buildings without major modifications."
  },
  {
    heading: "Quiet and Smooth Operation",
    text: "Hyundai home elevators are engineered for quiet and smooth operation, ensuring a comfortable ride without disturbing the peace of your home."
  },
  {
    heading: "Safety Features",
    text: "Equipped with advanced safety features such as emergency stop buttons, battery backup, and door sensors, our elevators provide peace of mind for you and your family."
  },
  {
    heading: "Energy Efficiency",
    text: "Our elevators are designed to be energy-efficient, reducing power consumption and contributing to a greener environment."
  }
];

const keyFeatures1 = [
  {
    heading: "Customizable Cabins",
    text: "Choose from a range of cabin finishes, lighting options, and control panels to create an elevator that perfectly matches your home’s decor."
  },
  {
    heading: "User-Friendly Controls",
    text: "Easy-to-use control panels and intuitive interfaces make our elevators accessible to all family members."
  },
  {
    heading: "Smooth Ride Quality",
    text: "Advanced control systems ensure a smooth and comfortable ride every time."
  },
  {
    heading: "Durability and Reliability",
    text: "Built with high-quality materials and robust engineering, Hyundai home elevators are designed for long-lasting performance."
  }
];

const applications = [
  {
    heading: "Private Residences",
    text: "Enhance the accessibility and luxury of your home with a stylish and efficient elevator."
  },
  {
    heading: "Multi-Story Homes",
    text: "Make moving between floors effortless and convenient, especially for elderly family members or those with mobility challenges."
  },
  {
    heading: "Luxury Apartments",
    text: "Add a touch of sophistication and convenience to your apartment with a compact and elegant home elevator."
  }
];

const escalatorFeatures = [
  {
    heading: "Advanced Technology",
    text: "Hyundai escalators are equipped with state-of-the-art technology that ensures smooth operation and minimal maintenance."
  },
  {
    heading: "Safety Features",
    text: "Our escalators come with multiple safety features, including emergency stop buttons, handrail sensors, and automatic lubrication systems, to ensure the safety of all users."
  },
  {
    heading: "Energy Efficiency",
    text: "Designed with energy-saving features, our escalators help reduce power consumption and operational costs, contributing to a greener environment."
  },
  {
    heading: "Durability and Reliability",
    text: "Built with high-quality materials and precision engineering, Hyundai escalators are known for their durability and long-lasting performance."
  },
  {
    heading: "Customizable Designs",
    text: "We offer a variety of design options to match the aesthetic of your building, ensuring that our escalators blend seamlessly with your interior design.",
    classname: "borderbotam"

  }
];

const escalatorKeyFeatures = [
  {
    heading: "Smooth Ride Quality",
    text: "Advanced control systems and precision engineering ensure a smooth and comfortable ride."
  },
  {
    heading: "High Capacity",
    text: "Our escalators are designed to handle high passenger volumes, making them ideal for busy environments."
  },
  {
    heading: "Quiet Operation",
    text: "Noise reduction technology ensures a quiet and peaceful ride, enhancing the comfort of passengers."
  },
  {
    heading: "User-Friendly Interface",
    text: "Easy-to-use control panels and intuitive interfaces make our escalators accessible to all users.",
    classname: "borderbotam"

  }
];

const escalatorApplications = [
  {
    heading: "Shopping Malls",
    text: "Enhance the shopping experience by providing smooth and efficient transportation between floors."
  },
  {
    heading: "Airports",
    text: "Ensure the efficient movement of passengers through terminals with our reliable escalator solutions."
  },
  {
    heading: "Train Stations",
    text: "Improve the flow of people in busy train stations, ensuring safe and efficient transportation."
  },
  {
    heading: "Commercial Buildings",
    text: "Facilitate the movement of people in office buildings, hotels, and other commercial spaces.",
    classname: "borderbotam"

  }
];


const movingWalkFeatures = [
  {
    heading: "Advanced Technology",
    text: "Hyundai moving walks are equipped with cutting-edge technology that ensures smooth and quiet operation, enhancing the overall user experience."
  },
  {
    heading: "Safety Features",
    text: "Our moving walks come with multiple safety features, including emergency stop buttons, handrail sensors, and anti-slip surfaces, to ensure the safety of all users."
  },
  {
    heading: "Energy Efficiency",
    text: "Designed with energy-saving features, our moving walks help reduce power consumption and operational costs, contributing to a greener environment."
  },
  {
    heading: "Durability and Reliability",
    text: "Built with high-quality materials and precision engineering, Hyundai moving walks are known for their durability and long-lasting performance."
  },
  {
    heading: "Customizable Designs",
    text: "We offer a variety of design options to match the aesthetic of your building, ensuring that our moving walks blend seamlessly with your interior design.",
    classname: "borderbotam"

  }
];

const movingWalkKeyFeatures = [
  {
    heading: "Smooth Ride Quality",
    text: "Advanced control systems and precision engineering ensure a smooth and comfortable ride."
  },
  {
    heading: "High Capacity",
    text: "Our moving walks are designed to handle high passenger volumes, making them ideal for busy environments."
  },
  {
    heading: "Quiet Operation",
    text: "Noise reduction technology ensures a quiet and peaceful ride, enhancing the comfort of passengers."
  },
  {
    heading: "User-Friendly Interface",
    text: "Easy-to-use control panels and intuitive interfaces make our moving walks accessible to all users.",
    classname: "borderbotam"

  }
];


const movingWalkApplications = [
  {
    heading: "Airports",
    text: "Ensure the efficient movement of passengers through terminals with our reliable moving walk solutions."
  },
  {
    heading: "Shopping Malls",
    text: "Enhance the shopping experience by providing smooth and efficient transportation between different sections of the mall."
  },
  {
    heading: "Exhibition Centers",
    text: "Facilitate the movement of visitors in large exhibition centers, ensuring a seamless experience."
  },
  {
    heading: "Public Transport Hubs",
    text: "Improve the flow of people in busy train, metro railway stations and bus terminals, ensuring safe and efficient transportation."
  }
];




function Products() {

  const [height, setHeight] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
      // Function to update state based on screen size
      const updateScreenSize = () => {
          const width = window.innerWidth;
          const height = window.innerHeight;
          setScreenSize({ width, height });
          setIsMobile(width <= 768); // Example breakpoint: 768px
      };

      // Initial check
      updateScreenSize();

      // Add event listener for window resize
      window.addEventListener("resize", updateScreenSize);

      // Cleanup event listener on component unmount
      return () => window.removeEventListener("resize", updateScreenSize);
  }, []);



  const elementRef = useRef(null);



  const products = [
    { image: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/4d6de4133932561.61ca03fceac2c.jpg", title: "Capsule Elevators", link: "capsule-elevators" },
    { image: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/8fa263177262991.64d383c2cc16d.gif", title: "ELEVATOR INTERIOR DESIGN", link: "passenger-Elevators" },
    { image: "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/40b9c124798881.5633a41426cfa.jpg", title: "Elevator visualization.", link: "passenger-elevators-autodoor" },
    { image: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/cc03d250393873.58cfc758587ce.jpg", title: " Elevator interior design", link: "escalators-interior" },

  ]


  const products2 = [
    { image: "https://img.freepik.com/premium-photo/modern-escalators_323624-188.jpg?w=1060", title: "long escalator", link: "long-escalator" },
    { image: "https://img.freepik.com/premium-photo/mechanical-marvel-detail-shot-reveals-escalator-urban-building-subway-station_892776-1984.jpg?w=900", title: "Outdoor Escalators", link: "outdoor-escalators" },
    { image: "https://img.freepik.com/premium-photo/modern-luxury-escalators-with-staircase_49071-941.jpg?w=900", title: "Modern luxury escalators", link: "passenger-elevators-autodoor" },
    { image: "https://img.freepik.com/free-photo/low-angle-shot-escalator-going-up-metro-station-vijzelgracht-netherlands_181624-4984.jpg?t=st=1713350524~exp=1713354124~hmac=2e8c6dd4a4b62a92987066f26908bef6a75f978c5cfa2bb4683e41e387a03a19&w=900", title: "Escalator & Moving Walkway", link: "escalators-interior" },

  ]


  useEffect(() => {
    const updateHeight = () => {
      // if (elementRef.current) {
      //   setHeight(elementRef.current.clientHeight = elementRef.current.clientHeight-10%);
      // }

      if (elementRef.current) {
        const newHeight = elementRef.current.clientHeight * 0.9; // Reduce by 10%
        setHeight(newHeight);
      }
    };

    // Update height on mount
    updateHeight();

    // Listen for window resize
    window.addEventListener('resize', updateHeight);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []); // Empty dependency array to run only on mount



  const toggleVisibility = () => {
    if (window.pageYOffset > height) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };


  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, [height]);





  return (
    <>
    {isMobile ?
     <Container fluid className='mt-5 mb-5' style={{position:'relative'}} >
     <Tab.Container id="left-tabs-example" className="position-left-fix" defaultActiveKey="first">
       <div ref={elementRef} style={{position:'relative'}} >
         <Row className='main-row' style={{position:'relative'}} >

           <Col sm={3} style={{ paddingRight: "0px", display: 'flex', justifyContent: 'center' }}>
             <div >
               <div className='text-center  section-title '><p >Escalators</p></div>

               <Nav variant="pills" className="flex-row escalator-nav-pills" >
                 <Nav.Item className="mt-2" style={{width:"100%"}}>
                   <Nav.Link eventKey="first">Escalators</Nav.Link>
                 </Nav.Item>
                 <Nav.Item className="mt-2">
                   <Nav.Link eventKey="second">Travelators</Nav.Link>
                 </Nav.Item>


               </Nav>
             </div>
           </Col>
           <Col sm={5} className='' style={{ paddingLeft: "10px" }}>
             <Tab.Content>
               <Tab.Pane eventKey="first">
                 <div>
                   <h4 style={{ fontSize: '18px' }}>Efficient and Reliable Escalator Solutions by Hyundai</h4>
                   <p>At Techno Elevators LLC, we provide a range of Hyundai escalators designed to ensure smooth and efficient transportation in high-traffic areas. Our escalators are built with advanced technology and robust engineering to deliver reliable performance and enhance the flow of people in various settings.</p>
                   <h4 className='text-start mt-4 mb-4' style={{ fontSize: '18px' }}>
                     Why Choose Hyundai Escalators?
                   </h4>
                   {escalatorFeatures.map((item) => {
                     return (
                       <>
                         <h5 className="about-sideheading" >{item.heading}</h5>
                         <div className={item.classname} >
                           <p>{item.text}</p>
                         </div>
                       </>
                     )
                   })}
                   <h4 className='text-start mt-4 mb-4' style={{ fontSize: '18px' }}>Key Features</h4>
                   {escalatorKeyFeatures.map((item) => {
                     return (
                       <>
                         <h5 className="about-sideheading" >{item.heading}</h5>
                         <div className={item.classname} >
                           <p>  {item.text}</p>
                         </div>
                       </>
                     )
                   })}

                   <h4 className='text-start  mb-4' style={{ fontSize: '18px' }}>Applications</h4>
                   <p>Hyundai escalators are suitable for a variety of applications, including:</p>

                   {escalatorApplications.map((item) => {
                     return (
                       <>
                         <h5 className="about-sideheading" >{item.heading}</h5>
                         <div>
                           <p>{item.text}</p>
                         </div>
                       </>
                     )
                   })}
                 </div>

               </Tab.Pane>

               <Tab.Pane eventKey="second">

                 <div>
                   <h4 style={{ fontSize: '18px' }}>Seamless and Efficient Transportation with Hyundai Moving Walks</h4>
                   <p>At Techno Elevators LLC, we offer Hyundai moving walks designed to provide smooth and efficient horizontal transportation in various settings. Our moving walks are engineered with advanced technology to ensure reliability, safety, and energy efficiency, making them ideal for high-traffic areas.</p>
                   <h4 style={{ fontSize: '18px' }}>Why Choose Hyundai Moving Walks?</h4>
                   {movingWalkFeatures.map((item) => {
                     return (
                       <>
                         <h5 className="about-sideheading" >{item.heading}</h5>
                         <div className={item.classname} >
                           <p  >  {item.text}</p>

                         </div></>
                     )
                   })}
                   <h4 className='text-start mb-4' style={{ fontSize: '18px' }}>Key Features</h4>
                   {movingWalkKeyFeatures.map((item) => {
                     return (
                       <>
                         <h5 className="about-sideheading" >{item.heading}</h5>
                         <div className={item.classname} >
                           <p>{item.text}</p>
                         </div></>
                     )
                   })}
                   <h4 className='text-start  mb-4' style={{ fontSize: '18px' }}>Applications</h4>
                   <p>Hyundai moving walks are suitable for a variety of applications, including:</p>
                   {movingWalkApplications.map((item) => {
                     return (
                       <>
                         <h5 className="about-sideheading" >{item.heading}</h5>
                         <div >
                           <p>{item.text}</p>
                         </div></>
                     )
                   })}
                 </div>
               </Tab.Pane>

               <Tab.Pane eventKey="third">
                 <h5 >Freight Elevators</h5>
                 <div>
                   <p>Robust and reliable solutions for transporting goods in industrial and commercial settings.</p>
                 </div>
               </Tab.Pane>

             </Tab.Content>
           </Col>
           <Col lg={4} >
             <img src={'/Images/sliders/side-glass-escalator.jpg'} width="100%" />
           </Col>
         </Row>
       </div>
     </Tab.Container>
    
   </Container>
  
  
  :
      <Container fluid className='mt-5 mb-5' style={{position:'relative'}} >
        <Tab.Container id="left-tabs-example" className="position-left-fix" defaultActiveKey="first">
          <div ref={elementRef} style={{position:'relative'}} >
            <Row className='main-row' style={{position:'relative'}} >

              <Col sm={3} style={{ paddingRight: "0px", display: 'flex', justifyContent: 'center' }}>
                <div className={isVisible ? 'position-left-fix-div-scroll' : 'position-left-fix-div'}>
                  <div className='text-center  section-title '><p >Escalators</p></div>

                  <Nav variant="pills" className="flex-row escalator-nav-pills">
                    <Nav.Item className="mt-2">
                      <Nav.Link eventKey="first">Escalators</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mt-2">
                      <Nav.Link eventKey="second">Travelators</Nav.Link>
                    </Nav.Item>


                  </Nav>
                </div>
              </Col>
              <Col sm={5} className='' style={{ paddingLeft: "0px" }}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div>
                      <h4 style={{ fontSize: '18px' }}>Efficient and Reliable Escalator Solutions by Hyundai</h4>
                      <p>At Techno Elevators LLC, we provide a range of Hyundai escalators designed to ensure smooth and efficient transportation in high-traffic areas. Our escalators are built with advanced technology and robust engineering to deliver reliable performance and enhance the flow of people in various settings.</p>
                      <h4 className='text-start mt-4 mb-4' style={{ fontSize: '18px' }}>
                        Why Choose Hyundai Escalators?
                      </h4>
                      {escalatorFeatures.map((item) => {
                        return (
                          <>
                            <h5 className="about-sideheading" >{item.heading}</h5>
                            <div className={item.classname} >
                              <p>{item.text}</p>
                            </div>
                          </>
                        )
                      })}
                      <h4 className='text-start mt-4 mb-4' style={{ fontSize: '18px' }}>Key Features</h4>
                      {escalatorKeyFeatures.map((item) => {
                        return (
                          <>
                            <h5 className="about-sideheading" >{item.heading}</h5>
                            <div className={item.classname} >
                              <p>  {item.text}</p>
                            </div>
                          </>
                        )
                      })}

                      <h4 className='text-start  mb-4' style={{ fontSize: '18px' }}>Applications</h4>
                      <p>Hyundai escalators are suitable for a variety of applications, including:</p>

                      {escalatorApplications.map((item) => {
                        return (
                          <>
                            <h5 className="about-sideheading" >{item.heading}</h5>
                            <div>
                              <p>{item.text}</p>
                            </div>
                          </>
                        )
                      })}
                    </div>

                  </Tab.Pane>

                  <Tab.Pane eventKey="second">

                    <div>
                      <h4 style={{ fontSize: '18px' }}>Seamless and Efficient Transportation with Hyundai Moving Walks</h4>
                      <p>At Techno Elevators LLC, we offer Hyundai moving walks designed to provide smooth and efficient horizontal transportation in various settings. Our moving walks are engineered with advanced technology to ensure reliability, safety, and energy efficiency, making them ideal for high-traffic areas.</p>
                      <h4 style={{ fontSize: '18px' }}>Why Choose Hyundai Moving Walks?</h4>
                      {movingWalkFeatures.map((item) => {
                        return (
                          <>
                            <h5 className="about-sideheading" >{item.heading}</h5>
                            <div className={item.classname} >
                              <p  >  {item.text}</p>

                            </div></>
                        )
                      })}
                      <h4 className='text-start mb-4' style={{ fontSize: '18px' }}>Key Features</h4>
                      {movingWalkKeyFeatures.map((item) => {
                        return (
                          <>
                            <h5 className="about-sideheading" >{item.heading}</h5>
                            <div className={item.classname} >
                              <p>{item.text}</p>
                            </div></>
                        )
                      })}
                      <h4 className='text-start  mb-4' style={{ fontSize: '18px' }}>Applications</h4>
                      <p>Hyundai moving walks are suitable for a variety of applications, including:</p>
                      {movingWalkApplications.map((item) => {
                        return (
                          <>
                            <h5 className="about-sideheading" >{item.heading}</h5>
                            <div >
                              <p>{item.text}</p>
                            </div></>
                        )
                      })}
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="third">
                    <h5 >Freight Elevators</h5>
                    <div>
                      <p>Robust and reliable solutions for transporting goods in industrial and commercial settings.</p>
                    </div>
                  </Tab.Pane>

                </Tab.Content>
              </Col>
              <Col lg={4} className={isVisible ? "position-right-fix-scroll" : "position-right-fix"}>
                <img src={'/Images/sliders/side-glass-escalator.jpg'} width="100%" />
              </Col>
            </Row>
          </div>
        </Tab.Container>
       
      </Container>
}
      
      <Container fluid style={{ padding: '0px'}} className=''>
        <Footer />

      </Container>
    </>
  )
}

export default Products


