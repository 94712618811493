import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { IoLocationSharp, IoPricetags } from 'react-icons/io5';
import { GoProject } from 'react-icons/go';
import { MdOutlinePeople } from 'react-icons/md';
import CountUp from 'react-countup';

const Counter = ({ icon: Icon, endValue, duration, text, customValue }) => (
    <Col lg={3} xs={12} md={3} className="text-center">
        <div className='all-circle-main-div'>
        <div className="circle-wrapper" data-aos="zoom-out">
            <div className="success circle"></div>
            <div className="icon">
                <Icon color="white" size="40px" />
            </div>
        </div>
        </div>
        {!customValue ? (
            <CountUp
                end={endValue}
                duration={duration}
                style={{ color: 'white', fontSize: '40px', width: '100px', fontWeight: '600' }}
            />
        ) : (
            <h3 style={{ color: 'white', fontSize: '30px', width: '100%', fontWeight: '600' }}>{customValue}</h3>
        )}
        <div>
            <h4 style={{ color: 'white', fontSize: '20px', fontWeight: '100' }}>{text}</h4>
        </div>

    </Col>
);

const CounterSection = () => (
    <Container>
        <Row className="d-flex justify-content-center counter-section-row">
            <Counter
                icon={IoLocationSharp}
                endValue={17}
                duration={5.75}
                customValue="17+"
                text="Years of presence in GCC"
            />
            <Counter
                icon={GoProject}
                endValue={100}
                duration={5.75}
                text="Projects"
                customValue="1000+"
            />
            <Counter
                icon={MdOutlinePeople}
                endValue={85}
                duration={5.75}
                text="Employees"
                customValue="50+"
            />
            <Counter
                icon={IoPricetags}
                endValue={25}
                duration={5.75}
                text="Solution provider"
                customValue="Comprehensive"
            />
        </Row>
    </Container>
);

export default CounterSection;
