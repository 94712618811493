import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Tab, Tabs, Nav } from 'react-bootstrap';
import Footer from './Footer';

function Projects() {
  const projectData = {
    ResidentialProjects: [
      {
        name: "Al Hail Villas",
        description: "Installation of Hyundai home elevators in a luxury villa complex, enhancing accessibility and adding a touch of sophistication to each home.",
        image: "/Images/gallery/1.jpg"
      },
      {
        name: "Muscat Heights Apartments",
        description: "Deployment of passenger elevators in a high-rise residential building, ensuring smooth and efficient transportation for residents.",
        image: "/Images/gallery/3.jpg"
      }
    ],
    CommercialProjects: [
      {
        name: "Oman Business Tower",
        description: "Implementation of high-capacity passenger elevators in a premier office building, improving the flow of people and enhancing the overall user experience.",
        image: "/Images/gallery/4.jpg"
      },
      {
        name: "City Centre Mall",
        description: "Installation of escalators and moving walks to facilitate seamless movement of shoppers across multiple floors and sections of the mall.",
        image: "/Images/gallery/5.jpg"
      }
    ],
    HospitalityProjects: [
      {
        name: "Grand Hyatt Muscat",
        description: "Integration of Hyundai elevators in a five-star hotel, providing guests with a luxurious and comfortable vertical transportation experience.",
        image: "/Images/gallery/8.jpg"
      },
      {
        name: "Beach Resort Salalah",
        description: "Deployment of passenger and service elevators to meet the high demands of a bustling resort environment.",
        image: "/Images/gallery/9.jpg"
      }
    ],
    PublicSectorProjects: [
      {
        name: "Muscat International Airport",
        description: "Installation of moving walks and escalators to ensure efficient passenger movement through the airport terminals.",
        image: "/Images/gallery/10.jpg"
      },
      {
        name: "Royal Hospital",
        description: "Installing Hyundai elevators to enhance accessibility and streamline transportation for patients and staff throughout the hospital.",
        image: "/Images/gallery/11.jpg"
      }
    ],
    IndustrialProjects: [
      {
        name: "Sohar Industrial Port",
        description: "Installing freight elevators to meet the heavy-duty transportation demands of an industrial port facility.",
        image: "/Images/gallery/12.jpg"
      },
      {
        name: "Oman Cement Company",
        description: "Deployment of robust and reliable freight elevators to facilitate the movement of goods and materials within the plant.",
        image: "/Images/gallery/13.jpg"
      }
    ]
  };

  const [activeTab, setActiveTab] = React.useState(Object.keys(projectData)[0]);
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div >
      <Container fluid>
        {/* Header Section */}
        <Row className="text-center mt-5 mb-3">
          <div className="section-title">
            <h2>Our Projects</h2>
          </div>
          <Col lg={12}>
            <p className="text-center" style={{ width: '700px', margin: 'auto' }}>
              At Techno Elevators LLC, we take pride in our extensive portfolio of successfully executed projects. Our collaboration with Hyundai Elevator has enabled us to deliver top-notch vertical and horizontal transportation solutions across various sectors in Oman. Here are some of our notable projects.
            </p>
          </Col>
        </Row>
      </Container>
      <Container 

    
       >
        {/* Projects Section */}
        <Row className={isSticky ? 'sticky-top' : ''} >
          {/* Nav Pills (Left) */}
          <Col lg={3} className={isSticky ? 'mt-5' : 'mb-5'}  >
            <Nav variant="pills" className="flex-column">
              {Object.keys(projectData).map((category) => (
                <Nav.Item key={category}>
                  <Nav.Link
                    active={activeTab === category}
                    onClick={() => setActiveTab(category)}
                    className="text-center"
                    style={{ margin: "10px" }}
                  >
                    {category.replace(/([A-Z])/g, " $1").trim()}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>

          {/* Tab Content (Right) */}
          <Col lg={9} className={isSticky ? 'mt-5' : ''} >
            <Tabs activeKey={activeTab} onSelect={(key) => setActiveTab(key)} className="d-none">
              {Object.entries(projectData).map(([category, projects]) => (
                <Tab eventKey={category} key={category}>
                  <Row className="g-4 justify-content-center mx-auto mt-1">
                    {projects.map((project, index) => (
                      <Col
                        lg={6}
                        md={6}
                        sm={10}
                        key={index}
                        className="d-flex justify-content-center "
                      >
                        <Card className="project-card h-100 shadow-sm">

                          <Card.Body>
                            <Card.Title className="heading-names">{project.name}</Card.Title>
                            <Card.Text>{project.description}</Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Tab>
              ))}
            </Tabs>
          </Col>
        </Row>
      </Container>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Projects;
